import logo from './logo.svg';
import './App.css';
import Login from './component/login/Login';
// import Dashoborad from './component/Dashobard/Dashoborad';
import Sidebar from './component/Utills/Sidebar';
import Header from './component/Utills/Header';
import Footer from './component/Utills/Footer';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './Router/PrivateRouter';
import Dashoborad from './component/Dashobard/Dashoborad';
import Comfortable from './component/Comfortable/Comfortable';
import { ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import Specializations from './component/Specializations/Specializations';
import User from './component/User/User';
import ManageBooking from './component/Booking/ManageBooking';
import Review from './component/Review/Review';

import DeleteRequest from './component/deleteRequest/DeleteRequest';
import AppSetting from './component/AppSetting/AppSetting';
import Hospitals from './component/Hospitals/Hospitals';
import Caregivers from './component/Caregiver/Caregivers';
import Doctors from './component/ Doctors/ Doctors ';
import FamilyMember from './component/ FamilyMember/ FamilyMember';
import Blog from './component/Blogs/Blog';
import Events from './component/Event/Events';
import Contact from './component/Contact/Contact';
import Cmslist from './component/CMS/Cmslist';
import CmsEdit from './component/CMS/CmsEdit';
import Settings from './component/Setting/Settings';
import { url } from './Confing/Apiconfing';
import PageNotFound from './component/PageNotFound/PageNotFound';
import Reports from './component/Reports/Reports';

function App() {
  const Token = secureLocalStorage.getItem('token')
  return (
    <Routes>
      <Route path={url + "/"} element={<Login />} />
      <Route path={url + "/dashboard"} element={Token ? <Dashoborad /> : <Login />} />
      <Route path={url + "/comfortable-with"} element={Token ? <Comfortable /> : <Login />} />
      <Route path={url + "/specializations"} element={Token ? <Specializations /> : <Login />} />
      {/* <Route path={url + "/users"} element={Token ? <User /> : <Login />} /> */}
      <Route path={url + "/manage-booking"} element={Token ? <ManageBooking /> : <Login />} />
      <Route path={url + "/review"} element={Token ? <Review /> : <Login />} />
      <Route path={url + "/account-delete-request"} element={Token ? <DeleteRequest /> : <Login />} />
      <Route path={url + "/app-setting"} element={Token ? <AppSetting /> : <Login />} />
      <Route path={url + "/hospitals"} element={Token ? <Hospitals /> : <Login />} />
      {/* <Route path={url + "/caregivers"} element={Token ? <Caregivers /> : <Login />} /> */}
      {/* <Route path={url + "/doctors"} element={Token ? <Doctors /> : <Login />} /> */}
      {/* <Route path={url + "/familymember"} element={Token ? <FamilyMember /> : <Login />} /> */}
      <Route path={url + "/blogs"} element={Token ? <Blog /> : <Login />} />
      <Route path={url + "/events"} element={Token ? <Events /> : <Login />} />
      <Route path={url + "/contact"} element={Token ? <Contact /> : <Login />} />
      <Route path={url + "/cmslist"} element={Token ? <Cmslist /> : <Login />} />
      <Route path={url + "/cms-save"} element={Token ? <CmsEdit /> : <Login />} />
      <Route path={url + "/settings"} element={Token ? <Settings /> : <Login />} />
      <Route path={url + "/reports"} element={Token ? <Reports/> : <Login />} />

      <Route path={url + "*"} element={<PageNotFound />} />


    </Routes>

  );
}

export default App;
