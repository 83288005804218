import React, { useEffect, useState, useMemo } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import { toast } from 'react-toastify'
import userImage from '../../assests/images/dribbble.png'
import { formatDate } from '../DateFormate/DateFormate'

function User() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [serach , setSerach] = useState("")

    const handlePagination = (pagination) => {
        setIsLoading(true)
        Axios.get(`admin/get-all-users?page=${pagination?.pageIndex + 1}&limit=${pagination?.pageSize}&searchTerm=${serach}`).then((res) => {
            if (res.data.status) {
                setIsLoading(false)
                setData(res?.data?.data)
            }
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)    
        })
    }
    useEffect(()=>{
        if(serach.length >= 0){
            handlePagination()
        }
    },[serach])
    const handleStatusChange =(data)=>{
        console.log(data)
        const senddata= {
          id:data.id,
          roles:data.roles,
          status:data.active ? 0 : 1
        }
        Axios.post("admin/update-user-status",senddata).then((res)=>{
            if(res.data.status){
                toast.success(res.data.message)
                handlePagination()
            }else{
                toast.error(res.data.message)
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'S.NO',
                header: 'S.NO',
                accessorFn: (row, index) => {
                    return index + 1;
                },
                

            },
            {
                accessorKey: 'name',
                header: 'Name',
                accessorFn:(row)=>{
                    return row?.name == null ? "No Name" : row?.name
                },
              

            },
            {
                accessorKey: 'email',
                header: 'Email',
                enableEditing: false,
              
            },
            {
                accessorKey: 'phone_number',
                header: 'Mobile',
                accessorFn:(row)=>{
                    return row?.phone_number == null ? row?.office_number ||  "No Number" : row.phone_number
                },
              

            },
            {
                accessorKey: 'image',
                header: 'Image',
                accessorFn: (row) => {
                    return  row?.image  ? <img src={row.image} alt='image' width={"40px"} height={"40px"} /> : <img src={userImage} alt='image' width={"40px"} height={"40px"} />
                },
              

            },

            {
                accessorKey: 'createdAt',
                header: 'Created At',
                accessorFn: (row) => {
                    return formatDate(row?.createdAt)
                },
                // size: 130,

            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                accessorFn: (row) => {
                    return formatDate(row?.updatedAt)
                },
              

            },
            {
                accessorKey: 'status',
                header: 'Active',
                accessorFn: (row) => {
                    return (
                   <label class="switch">
                      <input type="checkbox" defaultChecked={row?.active == true ? true :false} onClick={()=>handleStatusChange(row)}/>
                       <span class="slider round"></span>
                          </label>
                    )
                },
              

            },
            {
                accessorKey: 'active',
                header: 'Is deleted',
                accessorFn:(row)=>{
                    return row?.deleted == true ? "TRUE" : "FALSE"
                },
              

            },
        ],

    );
    return (
        <div>
     {/* <Sidebar/>
      <div className="page-content">
       <Header/> */}
        <div className="px-3">
          {/* Start Content*/}
          <div className="container-fluid">
            {/* start page title */}
            {/* <div className="py-3 py-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="page-title mb-0"> USERS</h4>
                </div>
              </div>
            </div> */}
           
            <div className="row justify-content-between">
                {/* <h4 className="page-title mb-0"> Comfortable Withs</h4> */}
                <div className='col-md-4'> <input text="search" className="form-control" placeholder='search ' onChange={(e)=>setSerach(e.target.value)} /></div>
                    <div class="input-group mb-3">
                </div>
            </div> 

            <div className='row'>
                <div className='col-md-12'>
                    <Table columns={columns} data={data} isLoading={isLoading} handlePagination={handlePagination}/>
                </div>
            </div>

          </div> {/* container */}
        </div> 
      {/* <Footer/>
      </div> */}
    </div>
    )
}

export default User