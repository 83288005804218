import React, { Fragment, useEffect, useState , useMemo } from 'react'
import Sidebar from '../Utills/Sidebar'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import User from '../User/User'
import FamilyMember from '../ FamilyMember/ FamilyMember'
import Caregivers from '../Caregiver/Caregivers'
import Doctors from '../ Doctors/ Doctors '

function Dashoborad() {
  const [data , setData] = useState([])
  const [data1 ,setData1] = useState({})
  const [isLoading , setIsLoading] = useState(true)
  const [show , setShow] = useState("user")
 
   useEffect(()=>{
    Axios.get('users').then((res)=>{
      setData1(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
   },[])
   const handlePagination = (pagination) => {
    Axios.get('booking/getBookingRecentAdmin').then((res)=>{
      setData(res.data.data)
      setIsLoading(false)
    }).catch((err)=>{
      console.log(err)
    })
   }
        
   const columns = useMemo(
    () => [
      {
        accessorKey: 'S.NO',
        header: 'S.NO',
        accessorFn: (row, index) => {
          return index + 1;
        },
        size: 150, // Added size property
      },
      {
        accessorKey: 'id',
        header: 'Booking Id',
        enableEditing: false,
        size: 150, // Added size property
      },
      {
        accessorKey: 'family.name',
        header: 'Booking By	',
        size: 150, // Added size property
      },
      {
        accessorKey: 'caregiver.name',
        header: 'Booking For',
        size: 150, // Added size property
      },
      {
        accessorKey: 'slot',
        header: 'Slot Date',
        accessorFn: (row) => {
          return JSON.parse(row.slot)?.slot_date
        },
        size: 150, // Added size property
      },
      {
        accessorKey: 'slot-time',
        header: 'Slot Time',
        accessorFn: (row) => {
          return JSON.parse(row.slot)?.slot_data
        },
        size: 150, // Added size property
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150, // Added size property
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        accessorFn: (row) => {
          return new Date(row.createdAt).toLocaleDateString()
        },
        size: 150, // Added size property
      },
    ],
);


  return (
    <Fragment>
<div className="layout-wrapper">
 <Sidebar/>
  <div className="page-content">
   <Header/>
      {/* Start Content*/}
      <div className="container-fluid">
        {/* start page title */}
        <div className="py-3 py-lg-4">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="page-title mb-0">Dashboard</h4>
            </div>
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          <div className="col-md-6 col-xl-3">
            <div className="card">
              <div className={ show == "user" ?  "card-body1" : "card-body"} onClick={()=>setShow("user")}  >
                <div className="mb-4">
                  {/* <span className="badge badge-soft-primary float-end">Daily</span> */}
                  <h5 className="card-title mb-0">Total User</h5>
                </div>
                <div className="row d-flex align-items-center mb-4">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">
                      {data1?.c1}
                    </h2>
                  </div>
                  <div className="col-4 text-end">
                    {/* <span className="text-muted">12.5% <i className="mdi mdi-arrow-up text-success" /></span> */}
                  </div>
                </div>
                
              </div>
              {/*end card body*/}
            </div>{/* end card*/}
          </div> {/* end col*/}
          <div className="col-md-6 col-xl-3">
            <div className="card">
              <div className={ show == "family" ?  "card-body1" : "card-body"} onClick={()=>setShow("family")}>
                <div className="mb-4">
                  {/* <span className="badge badge-soft-primary float-end">Per Week</span> */}
                  <h5 className="card-title mb-0">Family Members</h5>
                </div>
                <div className="row d-flex align-items-center mb-4">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">
                    {data1?.c2}
                    </h2>
                  </div>
                  <div className="col-4 text-end">
                    {/* <span className="text-muted">18.71% <i className="mdi mdi-arrow-down text-danger" /></span> */}
                  </div>
                </div>
                
              </div>
              {/*end card body*/}
            </div>{/* end card*/}
          </div> {/* end col*/}
          <div className="col-md-6 col-xl-3">
            <div className="card">
              <div className={ show == "caregivers" ?  "card-body1" : "card-body"} onClick={()=>setShow("caregivers")}>
                <div className="mb-4">
                  {/* <span className="badge badge-soft-primary float-end">Per Month</span> */}
                  <h5 className="card-title mb-0">Care Givers</h5>
                </div>
                <div className="row d-flex align-items-center mb-4">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">
                    {data1?.c3}
                      
                    </h2>
                  </div>
                  <div className="col-4 text-end">
                    {/* <span className="text-muted">57% <i className="mdi mdi-arrow-up text-success" /></span> */}
                  </div>
                </div>
                
              </div>
              {/*end card body*/}
            </div>
            {/*end card*/}
          </div> {/* end col*/}
          <div className="col-md-6 col-xl-3">
            <div className="card">
              <div className={ show == "doctor" ?  "card-body1" : "card-body"} onClick={()=>setShow("doctor")} >
                <div className="mb-4">
                  {/* <span className="badge badge-soft-primary float-end">All Time</span> */}
                  <h5 className="card-title mb-0">Doctors</h5>
                </div>
                <div className="row d-flex align-items-center mb-4">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">
                    {data1?.c4}
                      
                    </h2>
                  </div>
                  <div className="col-4 text-end">
                    {/* <span className="text-muted">17.8% <i className="mdi mdi-arrow-down text-danger" /></span> */}
                  </div>
                </div>
                
              </div>
              {/*end card body*/}
            </div>{/* end card*/}
          </div> {/* end col*/}
        </div>
        {/* end row*/}
        <div className="row">
          <h4 className="page-title mb-0">{show == "user" ? "Total Users" : show == "family" ? "Family Members" : show =="caregivers" ? "Caregivers" : show =="doctor" ? "Doctor" :  "Total Users"}</h4>
          <div className='col-12 table-responsive'>
            {show == "user" ? <User/> :  show == "family" ? <FamilyMember/> : show == "caregivers" ?<Caregivers/> : show =="doctor" ?  <Doctors/>  : <User/>}
          </div>
        </div>
      </div> {/* container */}
  <Footer/>
  </div>
</div>

    </Fragment>
  )
}

export default Dashoborad