import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assests/images/Ucclogg.png'
import { useLocation } from 'react-router-dom'

function Sidebar() {
  const loaction = useLocation()

  return (
  <Fragment>
    
     <div className="main-menu">
    {/* Brand Logo */}
    <div className="logo-box">
      {/* Brand Logo Light */}
      <a  className="logo-light">
        <img src={Logo} alt="logo" className="logo-lg" height={28} />
        <img src={Logo} alt="small logo" className="logo-sm" height={28} />
      </a>
      {/* Brand Logo Dark */}
      <a  className="logo-dark">
        <img src={Logo} alt="dark logo" className="logo-lg" height={28} />
        <img src={Logo} alt="small logo" className="logo-sm" height={28} />
      </a>
    </div>
    {/*- Menu */}
    <div data-simplebar>
      <ul className="app-menu">
        <li className="menu-title">Menu</li>
        <li className="menu-item">
          <Link  to={'/dashboard'} className="menu-link waves-effect waves-light" >
            <span className="menu-icon"><i className="bx bx-home-smile" /></span>
            <span className="menu-text"> Dashboard </span>
            
          </Link>
        </li>
        <li className="menu-item">
          <Link  to={'/comfortable-with'} className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-layout" /></span>
            <span className="menu-text"> Comfortable Withs </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={'/specializations'}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-doughnut-chart" /></span>
            <span className="menu-text"> Specializations </span>
            {/*  */}
          </Link>
        </li>
        {/* <li className="menu-item">
          <Link to={"/users"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="fas fa-user" /></span>
            <span className="menu-text"> User </span>
          </Link>
        </li> */}
        {/* <li className="menu-item">
          <Link to={"/caregivers"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-briefcase-alt-2" /></span>
            <span className="menu-text"> Caregivers </span>
           
          </Link>
        </li> */}
        {/* <li className="menu-item">
          <Link to={"/doctors"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="fas fa-user-md" /></span>
            <span className="menu-text"> Doctors </span>
          
          </Link>
        </li> */}
        <li className="menu-item">
          <Link to={'/hospitals'}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="fas fa-hospital" /></span>
            <span className="menu-text"> Hospitals </span>
            {/*  */}
          </Link>
        </li>
        {/* <li className="menu-item">
          <Link to={"/familymember"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-home-smile" /></span>
            <span className="menu-text"> Family Members </span>
          
          </Link>
        </li> */}
        <li className="menu-item">
          <Link to={"/manage-booking"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-map" /></span>
            <span className="menu-text"> Manage Bookings </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/review"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-map-alt" /></span>
            <span className="menu-text">Review Caregivers And Doctors </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/blogs"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bxs-eraser" /></span>
            <span className="menu-text"> Blogs </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/contact"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="fas fa-user" /></span>
            <span className="menu-text"> Contact Us </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/cmslist"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-file" /></span>
            <span className="menu-text"> Cms Pages </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/events"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-calendar" /></span>
            <span className="menu-text"> Events </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={'/settings'}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-home-smile" /></span>
            <span className="menu-text"> Settings </span>
            {/*  */}
          </Link>
        </li>
        {/* <li className="menu-item">
          <a  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-home-smile" /></span>
            <span className="menu-text"> Subscriptions </span>
          </a>
        </li> */}
        <li className="menu-item">
          <Link to={"/app-setting"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-share-alt" /></span>
            <span className="menu-text"> App Settings </span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/reports"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-map-alt" /></span>
            <span className="menu-text">Reports</span>
            {/*  */}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={"/account-delete-request"}  className="menu-link waves-effect waves-light">
            <span className="menu-icon"><i className="bx bx-cookie" /></span>
            <span className="menu-text"> Delete Account Requests </span>
            {/*  */}
          </Link>
        </li>
      </ul>
    </div>
  </div>
  </Fragment>
  )
}

export default Sidebar