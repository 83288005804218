import React, { useEffect, useState, useMemo } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from 'yup';
import Clsoe from '../../assests/images/close.jpg'
import { formatDate } from '../DateFormate/DateFormate'
function Blog() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [serach, setSerach] = useState('')
    const [open, setOpen] = useState(false)
    const [blogdata, setBlogdata] = useState({})

    const blogsSchema = yup.object().shape({
        title: yup
            .string()
            .required("Title is required")
            .max(100, "Title must be at most 100 characters long"),

        Image: yup
            .mixed()
            .required("Image is required"),
        description: yup
            .string()
            .required("Description is required")
            .max(100, "Description must be at most 100 characters long"),
    });

    const { register, setValue, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(blogsSchema)
    });
    const handlePagination = (pagination) => {
        setIsLoading(true)
        Axios.get(`admin/get-all-blogs?page=${pagination?.pageIndex + 1}&limit=${pagination?.pageSize}&searchTerm=${serach}`).then((res) => {
            if (res.data.status) {
                setData(res?.data?.data)
                setIsLoading(false)
                setBlogdata({})
                
                // setPagination(res.data.totalItems)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (serach.length >= 0) {
            handlePagination()
        }
    }, [serach])
    const handleOpen = (id) => {
        setOpen(true)
        setBlogdata(id)
        setValue('Image',id?.images[0])
    }

    const handledelete = (id) => {
        if (window.confirm('Are you sure you want to delete this blog?')) {
            const data = {
                id: id
            }
            Axios.post('admin/delete-blogs', data).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setOpen(false)
                    handlePagination()
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'S.NO',
                header: 'S.NO',
                accessorFn: (row, index) => {
                    return index + 1;
                },
                size: 190,

            },
            {
                accessorKey: 'title',
                header: 'Title',
                enableEditing: false,
                size: 190,
            },
            {
                accessorKey: 'author',
                header: 'Author',
                accessorFn: (row) => {
                    return row?.author == null ? "No Name" : row?.author
                },
                size: 190,


            },

            {
                accessorKey: 'images',
                header: 'Image',
                accessorFn: (row) => {
                    return <img src={row?.images[0]} alt='image' width={"50px"} height={"50px"} />
                },
                size: 190,

            },
            // {
            //     accessorKey: 'description',
            //     header: 'Description',
            // },


            {
                accessorKey: 'createdAt',
                header: 'Created At',
                accessorFn: (row) => {
                    return formatDate(row.createdAt)
                },
                size: 190,

            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                accessorFn: (row) => {
                    return formatDate(row.updatedAt)
                },
                size: 190,

            },
            {
                accessorKey: 'actions',
                header: 'Actions',
                accessorFn: (row, index) => {
                    return (
                        <div>
                            <button className="btn btn-primary me-md-2" onClick={() => handleOpen(row)}  >Edit</button>
                            <button className="btn btn-danger" onClick={() => handledelete(row.id)} >Delete</button>
                        </div>
                    );
                },
                size: 190,

            },
        ],

    );

    const handleImage = (e) => {
        setValue("Image", e)
    }
    const handlesubmit = (send) => {
        if (Object.keys(blogdata).length == 0) {
            const formData = new FormData();
            formData.append('title', send.title)
            formData.append('file', send.Image)
            formData.append('description', send.description)
            Axios.post('admin/create-blogs', formData).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setBlogdata({})
                    setOpen(false)
                    handlePagination()
                    window.location.reload()


                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            const formData = new FormData();
            formData.append('title', send.title)
            formData.append('file', send.Image)
            formData.append('description', send.description)
            formData.append('id', blogdata.id)
            Axios.post('admin/update-blogs', formData).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setBlogdata({})
                    setOpen(false)
                    handlePagination()
                    window.location.reload()

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const handleclso = () => {
        setOpen(false)
        setBlogdata({})
        window.location.reload()
    }
    const handlecancle = () => {
        setBlogdata({ ...blogdata, images: [''] });
        setValue('Image')
    }

    return (
        <Fragment>
            <div className="layout-wrapper">
                <Sidebar />
                <div className="page-content">
                    <Header />
                    <div className="px-3">
                        {/* Start Content*/}
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="py-3 py-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4 className="page-title mb-0">Blogs</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                {/* <h4 className="page-title mb-0"> Comfortable Withs</h4> */}
                                <div className='col-md-4'> <input text="search" className="form-control" placeholder='search ' onChange={(e) => setSerach(e.target.value)} /></div>
                                <div className='col-md-4 text-md-end'> <a className='btn btn-primary' onClick={() => setOpen(true)}>Create New Blog</a></div>

                                <div class="input-group mb-3">
                                </div>
                                <div className='row'>
                                    <Table columns={columns} data={data} isLoading={isLoading} handlePagination={handlePagination} />
                                </div>
                            </div>
                        </div> {/* container */}
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal show={open} onHide={handleclso} key={open}>
                <div>
                <Modal.Header closeButton>
                  <Modal.Title>Blogs</Modal.Title>
                    </Modal.Header>
                    <div className="modal-body customMODEL pb-0">
                        {/* <h2>Blogs</h2> */}
                        {/* <button className="btn btn-secondary" onClick={() => handleclso()}>Close</button> */}
                        <form className="common-form-area-left-inner-form" onSubmit={handleSubmit(handlesubmit)} >
                            <div className="form-row">
                                <div className="mb-4">
                                    <label className="form-label text-dark">Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        className="form-control"
                                        defaultValue={blogdata?.title || ''}
                                        {...register("title")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="title"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label text-dark">Description</label>
                                    <input
                                        type="text"
                                        id="description"
                                        name="description"
                                        className="form-control"
                                        defaultValue={blogdata?.description || ''}
                                        {...register("description")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="description"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label text-dark">Image</label>
                                    {blogdata?.images?.[0] ?
                                        <div>
                                            <img src={Clsoe} width={"22px"} height={"22px"} onClick={() => handlecancle()} />
                                            <img src={blogdata?.images?.[0]} alt='image' width={"40px"} height={"40px"} /> </div> :
                                        <input
                                            type="file"
                                            id="file"
                                            name="Image"
                                            className="form-control"
                                            accept="image/*"
                                            onChange={(e) => handleImage(e.target.files[0])}
                                        />
                                    }

                                    <ErrorMessage
                                        errors={errors}
                                        name="Image" // Fixed typo here
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer px-0">

                                <button className="btn btn-success mx-0">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>


        </Fragment>
    )
}

export default Blog