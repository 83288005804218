import React, { useEffect, useState, useMemo } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import { toast } from 'react-toastify'
import userImage from '../../assests/images/dribbble.png'
import { formatDate } from '../DateFormate/DateFormate'

function Reports() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [serach , setSerach] = useState("")

    const handlePagination = (pagination) => {
        setIsLoading(true)
        Axios.get(`admin/get-reported-users?page=${pagination?.pageIndex + 1}&limit=${pagination?.pageSize}&searchTerm=${serach}`).then((res) => {
            if (res.data.status) {
                setIsLoading(false)
                setData(res?.data?.data?.rows)
            }
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)    
        })
    }
    useEffect(()=>{
        if(serach.length >= 0){
            handlePagination()
        }
    },[serach])
    const handleStatusChange =(data)=>{
        const senddata= {
            id:data.reportedUserId,
            roles:data.reportedUser.roles,
            status:data.reportedUser.active ? 0 : 1
        }
        Axios.post("admin/update-user-status",senddata).then((res)=>{
            if(res.data.status){
                toast.success(res.data.message)
                handlePagination()
            }else{
                toast.error(res.data.message)
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'S.NO',
                header: 'S.NO',
                accessorFn: (row, index) => {
                    return index + 1;
                },
                size: 150,

            },
            {
                accessorKey: 'reportedByUser.name',
                header: 'Report By User',
                accessorFn:(row)=>{
                    return row?.reportedByUser?.name == null ? "No Name" : row?.reportedByUser?.name
                },
                size: 150,

            },
            {
                accessorKey: 'reportedUser.name',
                header: 'Reported User',
                accessorFn:(row)=>{
                    return row?.reportedUser?.name == null ? "No Name" : row?.reportedUser?.name
                },
                enableEditing: false,
                size: 150,
            },
            {
                accessorKey: 'description',
                header: 'Description',
                accessorFn:(row)=>{
                    return row?.description == null ? "No description" : row.description
                },
                size: 150,

            },

            {
                accessorKey: 'createdAt',
                header: 'Created At',
                accessorFn: (row) => {
                    return formatDate(row.createdAt)
                },
                // size: 150,

            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                accessorFn: (row) => {
                    return formatDate(row.updatedAt)
                },
                size: 150,

            },
            {
                accessorKey: 'status',
                header: 'Status',
                accessorFn: (row) => {
                    return (
                   <label class="switch">
                      <input type="checkbox" defaultChecked={row?.reportedUser?.active == true ? true :false} onClick={()=>handleStatusChange(row)}/>
                       <span class="slider round"></span>
                          </label>
                    )
                },
                size: 150,

            },
        ],

    );
    return (
        <div className="layout-wrapper">
     <Sidebar/>
      <div className="page-content">
       <Header/>
        <div className="px-3">
          {/* Start Content*/}
          <div className="container-fluid">
            {/* start page title */}
            <div className="py-3 py-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="page-title mb-0"> Reports</h4>
                </div>
              </div>
            </div>
           
            <div className="row justify-content-between">
            {/* <h4 className="page-title mb-0"> Comfortable Withs</h4> */}
            <div className='col-md-4'> <input text="search" className="form-control" placeholder='search ' onChange={(e)=>setSerach(e.target.value)} /></div>
            <div class="input-group mb-3">
            </div>
            <div className='row'>
              <Table columns={columns} data={data} isLoading={isLoading} handlePagination={handlePagination}/>
            </div>
            </div> 
          </div> {/* container */}
        </div> 
      <Footer/>
      </div>
    </div>
    )
}

export default Reports