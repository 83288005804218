import React, { useEffect, useState, useMemo } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from 'yup';
import Clsoe from '../../assests/images/close.jpg'
import { formatDate } from '../DateFormate/DateFormate'

function Events() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [serach, setSerach] = useState('')
    const [open, setOpen] = useState(false)
    const [eventdata, setEventdata] = useState({})

    const eventSchema = yup.object().shape({
        name: yup
            .string()
            .required("Name is required")
            .max(120, "Name must be at most 120 characters long"),
        Image: yup
            .mixed()
            .required("Image is required"),
        description: yup
            .string()
            .required("Description is required")
            .max(120, "Description must be at most 120 characters long"),
        notes: yup
            .string()
            .required("Notes is required")
            .max(120, "Notes must be at most 120 characters long"),
        venue: yup
            .string()
            .required("Venue is required")
            .max(120, "Venue must be at most 120 characters long"),
        address: yup
            .string()
            .required("Address is required")
            .max(120, "Address must be at most 120 characters long"),
        city: yup
            .string()
            .required("City is required")
            .max(120, "City must be at most 120 characters long"),
        date: yup
            .string()
            .required("Date is required")
    });

    const { register, setValue, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(eventSchema)
    });
    const handlePagination = (pagination) => {
        setIsLoading(true)
        Axios.get(`admin/get-all-events?page=${pagination?.pageIndex + 1}&limit=${pagination?.pageSize}&searchTerm=${serach}`).then((res) => {
            if (res.data.status) {
                setData(res?.data?.data)
                setIsLoading(false)
                // setPagination(res.data.totalItems)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (serach.length >= 0) {
            handlePagination()
        }
    }, [serach])
    const handleOpen = (id) => {
        console.log(id)
        setOpen(true)
        setEventdata(id)
    }

    const handledelete = (id) => {
        if (window.confirm('Are you sure you want to delete this Event?')) {
            const data = {
                id: id
            }
            Axios.post('admin/delete-events', data).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setOpen(false)
                    handlePagination()
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'S.NO',
                header: 'S.NO',
                accessorFn: (row, index) => {
                    return index + 1;
                },

            },
            {
                accessorKey: 'name',
                header: 'Name',
                enableEditing: false,
            },
            {
                accessorKey: 'venue',
                header: 'Venue',
                accessorFn: (row) => {
                    return row?.venue == null ? "No venue" : row?.venue
                },

            },
            {
                accessorKey: 'date',
                header: 'Event Date',
                accessorFn: (row) => {
                    return row?.date == null ? "No Address" : formatDate(row?.date)
                },


            },
            {
                accessorKey: 'city',
                header: 'City',
                accessorFn: (row) => {
                    return row?.city == null ? "No city" : row?.city
                },



            },
            // {
            //     accessorKey: 'description',
            //     header: 'Description',
            //     accessorFn:(row)=>{
            //         return row?.description == null ? "No Description" : row?.description
            //     }
            // },
            {
                accessorKey: 'address',
                header: 'Address',
                accessorFn: (row) => {
                    return row?.address == null ? "No Address" : row?.address
                },

            },

            {
                accessorKey: 'images',
                header: 'Image',
                accessorFn: (row) => {
                    return <img src={row?.images[0]} alt='image' width={"50px"} height={"50px"} />
                },


            },
            // {
            //     accessorKey: 'description',
            //     header: 'Description',
            // },


            {
                accessorKey: 'createdAt',
                header: 'Created At',
                accessorFn: (row) => {
                    return formatDate(row.createdAt)
                },


            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                accessorFn: (row) => {
                    return formatDate(row.updatedAt)
                },


            },
            {
                accessorKey: 'actions',
                header: 'Actions',
                accessorFn: (row, index) => {
                    return (
                        <div>
                            <button className="btn btn-primary me-md-2" onClick={() => handleOpen(row)}  >Edit</button>
                            <button className="btn btn-danger" onClick={() => handledelete(row.id)} >Delete</button>
                        </div>
                    );
                },

            },
        ],

    );

    const handleImage = (e) => {
        setValue("Image", e)
    }
    const handlesubmit = (send) => {
        if (Object.keys(eventdata).length == 0) {
            const formData = new FormData();
            formData.append('name', send.name)
            formData.append('file', send.Image)
            formData.append('city', send.city)
            formData.append('address', send.address)
            formData.append('notes', send.notes)
            formData.append('venue', send.venue)
            formData.append('date', send.date)
            formData.append('description', send.description)

            Axios.post('admin/create-events', formData).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setEventdata({})
                    setOpen(false)
                    handlePagination()
                  window.location.reload()


                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            const formData = new FormData();
            formData.append('name', send.name)
            formData.append('file', send.Image)
            formData.append('city', send.city)
            formData.append('address', send.address)
            formData.append('notes', send.notes)
            formData.append('venue', send.venue)
            formData.append('date', send.date)
            formData.append('description', send.description)
            formData.append('id', eventdata.id)
            Axios.post('admin/update-events', formData).then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message)
                    setEventdata({})
                    setOpen(false)
                    handlePagination()
                     window.location.reload()

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const handleclso = () => {
        setOpen(false)
        setEventdata({})
        window.location.reload()
    }
    const handlecancle = () => {
        setEventdata({ ...eventdata, images: [''] });
        setValue('Image')
    }
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 0);
    if(eventdata?.images){
        setValue('Image' ,eventdata?.images[0])
    }
    return (
        <Fragment>
            <div className="layout-wrapper">
                <Sidebar />
                <div className="page-content">
                    <Header />
                    <div className="px-3">
                        {/* Start Content*/}
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="py-3 py-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4 className="page-title mb-0">Events</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-between">
                                {/* <h4 className="page-title mb-0"> Comfortable Withs</h4> */}
                                <div className='col-md-4'> <input text="search" className="form-control" placeholder='search ' onChange={(e) => setSerach(e.target.value)} /></div>
                                <div className='col-md-4 text-md-end'>  <a className='btn btn-primary' onClick={() => setOpen(true)}>Create New</a> </div>
                                <div class="input-group mb-3">
                                </div>
                                <div className='row'>
                                    <Table columns={columns} data={data} isLoading={isLoading} handlePagination={handlePagination} />
                                </div>
                            </div>
                        </div> {/* container */}
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal show={open} className="modal-lg" onHide={handleclso} >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Event</Modal.Title>
                    </Modal.Header>
                    <div className="modal-body customMODEL pb-0" >
                        {/* <h2>Event</h2> */}
                        {/* <button className="btn btn-secondary" onClick={()=>handleclso()}>Close</button> */}
                        <form className="common-form-area-left-inner-form" onSubmit={handleSubmit(handlesubmit)} >
                            <div className="row">
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Name</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="name"
                                        className="form-control"
                                        defaultValue={eventdata.name || ''}
                                        {...register("name")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="name"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Description</label>
                                    <input
                                        type="text"
                                        id="description"
                                        name="description"
                                        className="form-control"
                                        defaultValue={eventdata.description || ''}
                                        {...register("description")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="description"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Notes</label>
                                    <input
                                        type="text"
                                        id="notes"
                                        name="notes"
                                        className="form-control"
                                        defaultValue={eventdata.notes || ''}
                                        {...register("notes")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="notes"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Venue</label>
                                    <input
                                        type="text"
                                        id="venue"
                                        name="venue"
                                        className="form-control"
                                        defaultValue={eventdata.venue || ''}
                                        {...register("venue")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="venue"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Address</label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        className="form-control"
                                        defaultValue={eventdata.address || ''}
                                        {...register("address")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="address"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        className="form-control"
                                        defaultValue={eventdata.city || ''}
                                        {...register("city")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="city"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark"> Start Date</label>
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        className="form-control"
                                        min={`${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`}
                                        defaultValue={eventdata?.date ? new Date(eventdata.date).toISOString().split('T')[0] : ''}
                                        {...register("date")}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="date"
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label text-dark">Image</label>
                                    {eventdata?.images?.[0] ?
                                        <div>
                                            <img src={Clsoe} width={"22px"} height={"22px"} onClick={() => handlecancle()} />
                                            <img src={eventdata?.images?.[0]} alt='image' width={"40px"} height={"40px"} /> </div> :
                                        <input
                                            type="file"
                                            id="file"
                                            name="Image"
                                            className="form-control"
                                            accept="image/*"
                                            onChange={(e) => handleImage(e.target.files[0])}
                                        />
                                    }

                                    <ErrorMessage
                                        errors={errors}
                                        name="Image" // Fixed typo here
                                        render={({ message }) => <p className='error' style={{ color: 'red' }}>{message}</p>}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">

                                <button className="btn btn-success">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>


        </Fragment>
    )
}

export default Events