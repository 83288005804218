import React, { Fragment, useEffect, useState } from 'react'
import Axios from '../../Confing/axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import Logo from '../../assests/images/Ucclogg.png'

function Login() {
  const [formdata, setFormdata] = useState({})
  const handleData = (e) => {
    const { name, value } = e.target; 
    setFormdata(prevFormData => ({ ...prevFormData, [name]: value }));
  };
  const navigate = useNavigate()


  const handleSave = (e) => {
    e.preventDefault(); // Prevent default form submission

    const data = {
      email: formdata.email,
      password: formdata.password,
      remember:true
    }
    console.log(data , formdata)
    axios.post('https://uniquecareconnect.xyz:8727/api/admin/login-admin', data).then((res) => {
      console.log(res)
      if (res.data.status) {
        navigate('/dashboard')
        toast.success(res.data.message)
        secureLocalStorage.setItem("token", res.data.token);
        secureLocalStorage.setItem("user", res.data.user);
        window.location.reload()
      }
    }).catch((err) => {
      console.log(err?.response?.data?.message,"---,<<<<<")
      toast.error("Sorry, the email or password you entered is incorrect. Please check your information and try again")
    })
  }
useEffect(()=>{
const token =  secureLocalStorage.getItem('token')
if(token){
  navigate("/dashboard")
}
},[])

  console.log(formdata ,"formdataformdatav")
  return (
    <Fragment>
      <div className='befar'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 mx-auto auth-logo mb-4">
                  <a className="logo-dark">
                    <span><img src={Logo} alt height={22} /></span>
                  </a>
                  <a className="logo-light">
                    <span><img src={Logo} alt height={22} /></span>
                  </a>
                </div>
                <form onSubmit={handleSave}>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="email">Email address</label>
                  <input className="form-control" type="email" name='email' id="email" required placeholder="Enter your email" onChange={handleData} />
                </div>
                <div className="form-group mb-3">
                  <a href="pages-recoverpw.html" className="text-muted float-end"><small /></a>
                  <label className="form-label" htmlFor="password">Password</label>
                  <input className="form-control" type="password" name='password' required id="password" placeholder="Enter your password" onChange={handleData} />
                </div>
                <div className="form-group mb-3">
                  <div className>
                    <input className="form-check-input" type="checkbox" id="remember" defaultChecked />
                    <label className="form-check-label ms-2" htmlFor="checkbox-signin">Remember me</label>
                  </div>
                </div>
                <div className="form-group mb-0 text-center">
                  <button className="btn btn-primary w-100" type="submit"  > Log In </button>
                </div>
                </form>
              </div> {/* end card-body */}
            </div>
            {/* end card */}
            {/* <div className="row mt-3">
              <div className="col-12 text-center">
                <p className="text-white-50"> <a href="pages-register.html" className="text-white-50 ms-1">Forgot your password?</a></p>
                <p className="text-white-50">Don't have an account? <a href="pages-register.html" className="text-white font-weight-medium ms-1">Sign Up</a></p>
              </div> 
            </div> */}
            {/* end row */}
          </div> {/* end col */}
        </div>
        {/* end row */}
      </div>
      </div>
      {/* App js */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />,

    </Fragment>
  )
}

export default Login