import React, { useEffect, useState, useMemo } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import Table from '../../Table/Table'
import { formatDate } from '../DateFormate/DateFormate'
import { toast } from 'react-toastify'

function Caregivers() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [serach, setSerach] = useState("")

    const handlePagination = (pagination) => {
        setIsLoading(true)
        Axios.get(`admin/get-all-caregivers?page=${pagination?.pageIndex + 1}&limit=${pagination?.pageSize}&searchTerm=${serach}`).then((res) => {
            if (res.data.status) {
                setIsLoading(false)
                setData(res?.data?.data)
            }
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)
        })
    }
    useEffect(() => {
        if (serach.length >= 0) {
            handlePagination()
        }
    }, [serach])
    const handleStatusChange =(data)=>{
        const senddata= {
          id:data.userId,
          roles:2,
          status:data?.user_details?.active ? 0 : 1
        }
        Axios.post("admin/update-user-status",senddata).then((res)=>{
            if(res.data.status){
                toast.success(res.data.message)
                handlePagination()
            }else{
                toast.error(res.data.message)
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'S.NO',
                header: 'S.NO',
                accessorFn: (row, index) => {
                    return index + 1;
                },
                
            },
            {
                accessorKey: 'name',
                header: 'Name',
                accessorFn: (row) => {
                    return row?.name == null ? "No Name" : row?.name
                },
            },
            // {
            //     accessorKey: 'desc',
            //     header: 'desc',
            //     enableEditing: false,
            // },
            {
                accessorKey: 'price',
                header: 'price',
                accessorFn: (row) => {
                    return row?.price == null ? "No Number" : row.price
                },
                
            },
            {
                accessorKey: 'latitude',
                header: 'Latitude',
                accessorFn: (row) => {
                    return row?.latitude == null ? "No Number" : row.latitude
                },
                
            },
            {
                accessorKey: 'longitude',
                header: 'Longitude',
                accessorFn: (row) => {
                    return row?.longitude == null ? "No Number" : row.longitude
                },
                
            },
            {
                accessorKey: 'imgId',
                header: 'Image',
                accessorFn: (row) => {
                    return <img src={row.imgId} alt='image' width={"40px"} height={"40px"} />
                },
                
            },

            {
                accessorKey: 'createdAt',
                header: 'Created At',
                accessorFn: (row) => {
                    return formatDate(row.createdAt)
                },
                
            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                accessorFn: (row) => {
                    return formatDate(row.updatedAt)
                },
                
            },
            {
                accessorKey: 'status',
                header: 'Active',
                accessorFn: (row) => {
                    return (
                   <label class="switch">
                      <input type="checkbox" defaultChecked={row?.user_details?.active == true ? true :false} onClick={()=>handleStatusChange(row)}/>
                       <span class="slider round"></span>
                          </label>
                    )
                },
              

            },
        ],

    );

    return (
        <Fragment>
            {/* <div className="layout-wrapper">
                <Sidebar />
                <div className="page-content">
                    <Header />
                    <div className="px-3"> */}
                        {/* Start Content*/}
                        <div className="container-fluid">
                            {/* start page title
                            <div className="py-3 py-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4 className="page-title mb-0"> Caregivers</h4>
                                    </div>
                                </div>
                            </div> */}
                            {/* <a className='btn btn-primary' onClick={() => setOpen(true)}>Create New</a> */}

                            <div className="row justify-content-between">
                                {/* <h4 className="page-title mb-0"> Comfortable Withs</h4> */}
                                <div className='col-md-4'> <input text="search" className="form-control" placeholder='search ' onChange={(e) => setSerach(e.target.value)} /></div>
                                <div class="input-group mb-3">
                                </div>
                                <div className='row'>
                                    <Table columns={columns} data={data} isLoading={isLoading} handlePagination={handlePagination} />
                                </div>
                            </div>
                        </div> {/* container */}
                    {/* </div> */}
                    {/* <Footer />
                </div>
            </div> */}


        </Fragment>
    )
}

export default Caregivers