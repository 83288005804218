import React, { useState } from 'react'
import { Fragment } from 'react'
import Header from '../Utills/Header'
import Footer from '../Utills/Footer'
import Sidebar from '../Utills/Sidebar'
import Axios from '../../Confing/axios'
import { toast } from 'react-toastify'
import secureLocalStorage from 'react-secure-storage'
import { Link, useNavigate } from 'react-router-dom'
// import { CKEditor } from 'ckeditor4-react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function CmsEdit() {
  const [data, setData] = useState(secureLocalStorage.getItem('CMSPAGE'))
  const navigate = useNavigate()
  const handleInputValues = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({ ...prevData, [name]: value }));
  }
  const [error, setError] = useState({})
  const [ckeditordata, setCkeditordata] = useState(data?.description ? data?.description : '')
  const handleSave = () => {
    if (data?.id) {
      const err = {}
      if (data?.name == "" || data?.name == undefined) {
        err.name = "Please Enter Name"
      }
      if (data?.title == "" || data?.title == undefined) {
        err.title = "Please Enter Title"
      }
      if (ckeditordata == "" || ckeditordata == undefined) {
        err.description = "Please Enter Description"
      }
      if (Object.keys(err).length > 0) {
        setError(err)
      } else {
        const salg = data.name.split(' ')
        const salgresult = salg.map(item => item.toLowerCase()).join('-');
        const senddata = {
          name: data.name,
          title: data.title,
          description: ckeditordata,
          id: data.id,
          slug: salgresult
        }

        Axios.post('admin/update-cms-page', senddata).then((res) => {
          console.log(res)
          if (res.data.status) {
            toast.success(res.data.message)
            secureLocalStorage.setItem("CMSPAGE", '')
            navigate('/cmslist')
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    } else {
      const err = {}
      if (data?.name == "" || data?.name == null) {
        err.name = "Please Enter Name"
      }
      if (data?.title == "" || data?.title == null) {
        err.title = "Please Enter Title"
      }
      if (ckeditordata == "" || ckeditordata == null) {
        err.description = "Please Enter Description"
      }
      if (Object.keys(err).length > 0) {
        setError(err)
      } else {
        const salg = data?.name.split(' ')
        const salgresult = salg.map(item => item.toLowerCase()).join('-');
        const senddata = {
          name: data.name,
          title: data.title,
          description: ckeditordata,
          slug: salgresult
        }

        Axios.post('admin/create-cms-page', senddata).then((res) => {
          console.log(res)
          if (res.data.status) {
            toast.success(res.data.message)
            secureLocalStorage.setItem("CMSPAGE", '')
            navigate('/cmslist')

          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }

  console.log(error)
  return (
    <Fragment>
      <div className="layout-wrapper">
        <Sidebar />
        <div className="page-content">
          <Header />
          <div className="px-3">
            {/* Start Content*/}
            <div className="container-fluid">
              <Link to="/cmslist" className="fa-solid fa-arrow-left"></Link>
              {/* start page title */}
              <div className="py-3 py-lg-4">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="page-title mb-0">{data?.name} CMS PAGE</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" name='name' className="form-control" value={data?.name || ''} onChange={handleInputValues} required />
                    <span style={{ color: 'red' }} >{error?.name}</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input type="text" name='title' className="form-control" value={data?.title || ''} onChange={handleInputValues} required />
                    <span style={{ color: 'red' }} >{error?.title}</span>

                  </div>
                  
                  {/* <textarea
                    className="form-control"
                    style={{ width: "100%", marginTop: "20px" }}
                    value={data?.description}
                    required
                    rows="8"
                    name='description'
                  /> */}
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={ckeditordata}
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor);

                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setCkeditordata(data)
                      }}
                    />
                    <span style={{ color: 'red' }} >{error?.description}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <button className="btn btn-success" onClick={() => handleSave()} >Save</button>
                </div>
              </div>
            </div> {/* container */}

          </div>
          <Footer />
        </div>
      </div>


    </Fragment>
  )
}

export default CmsEdit