import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigate  = useNavigate()
  const hanldeBack = ()=>{
    navigate("/dashboard")
  }
  return (
  <Fragment>
    <div class="section">
  <h1 class="error">404</h1>
  <div class="page">Ooops!!! The page you are looking for is not found</div>
  <a class="back-home" onClick={()=>hanldeBack()} >Back to home</a>
</div>
  </Fragment>
  )
}

export default PageNotFound